/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { Grommet, Layer } from 'grommet';
import {Helmet} from "react-helmet"

import Header from "../header"
import "./layout.css"
import 'gestalt/dist/gestalt.css';
import SideBar from "../sidebar";
import { colors } from "../../theme";

const theme = {
  global: {
    font: {
      family: 'Monda',
      size: '14px',
      height: '20px',
    },
    input: {
      padding: '20px 50px',
    },
    colors: {
      brand: colors.black,
    },
    control: {
      border: {
        radius: '0px',
        width: '2px',
        color: colors.black
      }
    }
  },
  button: {
    border: {
      radius: '0px'
    },
    padding: {
      vertical: '10px'
    }
  },
};

const Index = ({ children, rest }) => {
  const [visibleMenu, setVisibleMenu] = useState(false)

  return (
    <Grommet theme={theme} full>
      <>
        <Header visibleMenu={visibleMenu} setVisibleMenu={setVisibleMenu} />
        <Helmet>
          <script id="mcjs">
            {`
              !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/13bc3598e8a9303475a904627/8fd84b6eb18817175dae7b1da.js");
            `}
          </script>
        </Helmet>
        {children}
        {visibleMenu && (
          <Layer
            responsive
            full="vertical"
            modal={true}
            position="left"
            animation="slide"
            style={{ backgroundColor: colors.black, width: '100%', borderRadius: 0 }}
            onEsc={() => setVisibleMenu(false)}
            onClickOutside={() => setVisibleMenu(false)}
          >
            <SideBar visibleMenu={visibleMenu} setVisibleMenu={setVisibleMenu} />
          </Layer>
        )}
      </>
    </Grommet>
  )
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Index
