import React from 'react'
import { Box } from 'gestalt'
import { Match } from '@reach/router'
import { Box as GBox, Stack } from 'grommet'
import { HamburgerSpring } from 'react-animated-burgers'

import { List, ListItem, CopyRight, FixedBox } from './styles'
import SocialBar from '../social-bar';
import { colors } from '../../theme';
import { Link } from 'gatsby';

const ListItemRender = ({ index, path, name }) => (
  <Match path={path}>
    {props => (
      <ListItem active={props.match}>
        <Stack anchor="center" alignSelf="center" guidingChild="first" interactiveChild="last">
          <Box>
            <p>{index}</p>
          </Box>
          <Box>
            <Link to={path}>{name}</Link>
          </Box>
        </Stack>
      </ListItem>
    )}
  </Match>
)

const routes = [
  { name: 'HOME', path: '/' },
  { name: 'ABOUT', path: '/about' },
  { name: 'BLOG', path: '/blog' },
  { name: 'WORKS', path: '/works' },
  { name: 'CONTACT', path: '/contact' },
]

const SideBar = ({ setVisibleMenu, visibleMenu }) => {

  return (
    <Box
      alignItems="center"
      direction="row"
      display="flex"
      flex
      fit
      height="100vh"
      marginStart={-1}
      marginEnd={-1}
    >
      <Box mdDisplay="block" display="none" lgDisplay="block" smDisplay="none">
        <SocialBar darkMode />
      </Box>
      <GBox fill justify="center" align="center">
        <GBox fill justify="center" align="center">
          <List>
            {routes.map((obj, index) => <ListItemRender index={'0'+(index + 1)} name={obj.name} path={obj.path} />)}
          </List>
        </GBox>
        <Box padding={5} smPadding={2} justifyContent="center" alignItems="center" flex>
          <Box mdDisplay="none" display="block" lgDisplay="none" smDisplay="none" justifyContent="center" alignItems="center">
            <SocialBar darkMode hort />
          </Box>
          <CopyRight>COPYRIGHT &#9400; {new Date().getFullYear()} REX ISAAC RAPHAEL</CopyRight>
        </Box>
      </GBox>
      <Box mdDisplay="block" padding={4} display="none" lgDisplay="block" smDisplay="none" />
      <FixedBox
        padding={4}
      >
        <HamburgerSpring barColor={visibleMenu ? 'white' : colors.black} isActive={visibleMenu} toggleButton={() => setVisibleMenu(!visibleMenu)} />
      </FixedBox>
    </Box>
  )
}

export default SideBar
