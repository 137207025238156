import styled from 'styled-components'
import { Box } from 'grommet'
import { lighten } from 'polished'
import is from 'styled-is';
import { colors, responsive } from '../../theme';

export const CopyRight = styled.p`
  font-size: .7rem;
  color: ${lighten(0.5, colors.black)};
  font-family: 'Josefin Sans', sans-serif;
  text-align: center;
`;

export const FixedBox = styled(Box)`
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const List = styled.ul`
  list-style: none;
  justify-content: center;
`;

export const ListItem = styled.li`
  max-height: 20px;
  height: 20px;
  padding-bottom: 20px;
  
  p {
    color: ${lighten(0.2, colors.black)};
    font-size: 7rem;
    text-align: center;
    line-height: 0;
    font-family: 'Josefin Sans', sans-serif;
    visibility: hidden;
    
    ${responsive.xsAndLess} {
      font-size: 5rem;
    }
    
    
    ${responsive.xsAndLess} {
      font-size: 5rem;
    }
  }
 
  a {
    color: ${lighten(0.5, colors.black)};
    text-decoration-line: none;
    font-size: 3rem;
    text-align: center;
    font-weight: bold;
    line-height: 0;
    font-family: 'Open Sans', sans-serif;
    
    ${responsive.xsAndLess} {
      font-size: 2rem;
    }
  }
  text-align: center;


  ${is('active')`
    color: white;
    a {
      color: white;
      text-decoration-line: line-through;
      font-size: 3rem;
      text-align: center;
      font-family: 'Josefin Sans', sans-serif;
    }
    
    p {
      visibility: visible;
    }
  `};

    ${responsive.xsAndLess} {
      ${is('active')`
        a {
          font-size: 2rem;
        }
      `};
      :hover {
        font-size: 2rem;
      }
    }
    
  :hover {
    color: white;
    text-decoration-line: line-through;
    font-size: 3rem;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    
    a {
      color: white;
    }
    
    p {
      visibility: visible;
    }
  }
`;
