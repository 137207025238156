import PropTypes from "prop-types"
import React from "react"
import { Box } from "grommet"
import { Github, Twitter, Instagram, LinkedinOption } from "grommet-icons"
import { colors } from "../../theme"
import { Item } from "./styles"

const SocialBar = ({ darkMode, hort }) => (
  <>
    {hort ? (
      <Box pad={{ horizontal: "50px" }} justify="center" flex direction="row">
        <Item animation="pulse" hort>
          <a href="//github.com/juicycleff" rel="noopener noreferrer" target="_blank">
            <Github color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Item>
        <Item animation="pulse" hort>
          <a href="//twitter.com/weird_beird" rel="noopener noreferrer" target="_blank">
            <Twitter color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Item>
        <Item animation="pulse" hort>
          <a href="//instagram.com/weird_beird" rel="noopener noreferrer" target="_blank">
            <Instagram color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Item>
        <Box animation="pulse" hort>
          <a href="//linkedin.com/in/rexraphael" rel="noopener noreferrer" target="_blank">
            <LinkedinOption color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Box>
      </Box>
    ) : (
      <Box pad={{ horizontal: "50px" }}>
        <Item animation="pulse" vert>
          <a href="//github.com/juicycleff" rel="noopener noreferrer" target="_blank">
            <Github color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Item>
        <Item animation="pulse" vert>
          <a href="//twitter.com/rex_raph" rel="noopener noreferrer" target="_blank">
            <Twitter color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Item>
        <Item animation="pulse" vert>
          <a href="//instagram.com/weird_beird" rel="noopener noreferrer" target="_blank">
            <Instagram color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Item>
        <Box animation="pulse" vert>
          <a href="//linkedin.com/in/rexraphael" rel="noopener noreferrer" target="_blank">
            <LinkedinOption color={darkMode ? "white" : colors.black} size="25px"/>
          </a>
        </Box>
      </Box>
    )}
  </>
)

SocialBar.propTypes = {
  darkMode: PropTypes.bool,
}

SocialBar.defaultProps = {
  darkMode: false,
}

export default SocialBar
