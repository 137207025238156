import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { HamburgerSpring } from 'react-animated-burgers'
import { Box } from "gestalt"
import { Integration } from "grommet-icons"

import { Wrapper } from "./styles";
import { colors } from "../../theme";

const Header = ({ siteTitle, setVisibleMenu, visibleMenu }) => {

  return (
    <Wrapper>
      <Box
        alignItems="center"
        direction="row"
        display="flex"
        color="transparent"
        justifyContent="between"
      >
        <Link to="/">
          <Box paddingY={4} paddingX={8} color="white">
            <Integration style={{ margin: 3 }} size="40px" color={colors.black} />
          </Box>
        </Link>
        <div style={{ zIndex: 9999 }}>
        <Box paddingY={3} paddingX={5} color="white">
          <HamburgerSpring
            barColor={visibleMenu ? 'white' : colors.black}
            isActive={visibleMenu}
            toggleButton={() => setVisibleMenu(!visibleMenu)}
          />
        </Box>
        </div>
      </Box>
    </Wrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
