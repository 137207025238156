import styled from 'styled-components'
import { Box } from 'grommet'
import is from 'styled-is'
import { responsive } from "../../theme"

export const Item = styled(Box)`
  ${is('hort')`
    margin: 0 40px 0 0;
    ${responsive.xsAndLess} {
      margin: 0 20px 0 0;
    }
  `};
 
  ${is('vert')`
    margin: 0 0 40px 0;
    ${responsive.xsAndLess} {
      margin: 0 0 20px 0;
    }
  `};
`;

